<template>
  <div class="menu-container" ref="menuContainer">
    <!-- 树形结构 -->
    <div class="lab-type-container">
      <div class="title">实验分类</div>
      <el-input placeholder="请输入关键词" v-model="searchInputContent">
        <i slot="prefix" class="el-input__icon el-icon-search"></i>
      </el-input>
      <el-row>
        <el-tree
          :data="labTypeData"
          node-key="id"
          ref="tree"
          default-expand-all
          :filter-node-method="filterNode"
          :expand-on-click-node="false"
          class="tree-container"
          @node-click="handleTreeNodeClick"
        >
          <div
            class="custom-tree-node"
            style="
              display: flex;
              justify-content: space-between;
              width: 400px;
              height: 40px;
              line-height: 40px;
            "
            slot-scope="{ node, data }"
          >
            <div>{{ node.label }}</div>
            <div v-if="!assistFunc(node.label)" style="margin-right: 14px">
              <span
                style="
                  padding-right: 5px;
                  border-right: 1px solid #ccc;
                  color: red;
                  cursor: pointer;
                "
                @click="handleDeleteLabCategoryBtnClick(data)"
              >
                删除
              </span>
              <span
                style="
                  margin-left: 10px;
                  color: rgb(41, 82, 216);
                  cursor: pointer;
                "
                @click="handleEditLabCategoryBtnClick(data)"
              >
                编辑
              </span>
            </div>
            <div v-else style="margin-right: 14px">
              <span
                @click="handleAddLabCategoryBtnClick(data)"
                style="color: #36b8d8"
              >
                + 添加分类
              </span>
            </div>
          </div>
        </el-tree>
      </el-row>
    </div>
    <!-- 右侧实验列表 -->
    <div class="lab-list-container">
      <div class="title">实验列表</div>
      <el-row>
        <el-button
          icon="el-icon-circle-plus-outline"
          type="primary"
          v-throttle
          @click="handleAddLabBtnClick"
          >添加实验</el-button
        >
      </el-row>
      <el-row style="margin-top: 20px">
        <el-form inline :model="labFormCondition">
          <el-form-item>
            <span>实验编号</span>
            <el-input
              v-model="labFormCondition.labCode"
              placeholder="请输入"
              style="width: 180px; margin-left: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <span>实验名称</span>
            <el-input
              v-model="labFormCondition.labName"
              placeholder="请输入"
              style="width: 180px; margin-left: 10px"
            ></el-input>
          </el-form-item>
          <el-form-item style="margin-left: 10px">
            <el-button v-throttle @click="handleResetLabTableBtnClick"
              >重置</el-button
            >
            <el-button
              type="primary"
              v-throttle
              @click="handleQueryLabTableBtnClick"
              >查询</el-button
            >
          </el-form-item>
        </el-form>
      </el-row>
      <el-row>
        <el-table
          :header-cell-style="{
            'text-align': 'center',
            backgroundColor: '#dfe6ec',
          }"
          :cell-style="{ 'text-align': 'center' }"
          :data="menuTableDataList"
          border
        >
          <el-table-column
            label="序号"
            type="index"
            width="50px"
          ></el-table-column>
          <el-table-column
            label="实验编号"
            prop="experimentCode"
          ></el-table-column>
          <el-table-column
            label="实验名称"
            prop="experimentName"
          ></el-table-column>
          <el-table-column
            label="实验目的"
            prop="experimentPurpose"
            width="600px"
          ></el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scoped">
              <span
                style="
                  padding-right: 5px;
                  border-right: 1px solid #ccc;
                  color: red;
                  cursor: pointer;
                "
                @click="handleDeleteLabBtnClick(scoped.row.experimentId)"
                >删除</span
              >
              <span
                @click="handleEditLabBtnClick(scoped.row)"
                style="margin-left: 5px; color: #00f; cursor: pointer"
                >编辑</span
              >
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <!-- 分页组件 -->
      <el-row style="margin-top: 30px">
        <el-pagination
          @size-change="handleSizeChangeLabTableDataMsgPage"
          @current-change="handleCurrentChangeLabTableDataMsgPage"
          :current-page="labTableDataMsgPage.pageIndex"
          :page-sizes="[10, 20, 50, 100, 150, 200]"
          :page-size="labTableDataMsgPage.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="labTableDataMsgPage.pageTotal"
        ></el-pagination>
      </el-row>
    </div>
    <!-- 新增和编辑实验弹框 -->
    <el-dialog
      :title="addOrEditLabInfo === 1 ? '添加实验' : '编辑实验'"
      :visible.sync="addOrEditLabInfoDialogVisible"
      top="3%"
    >
      <div style="margin: 0 auto; width: 500px">
        <el-form
          :model="labInfoFormData"
          ref="labInfoFormData"
          :rules="rules"
          class="labInfoFormData"
        >
          <el-form-item prop="subjectId">
            <span class="redStar">所属科目</span>
            <el-input
              style="width: 400px; margin-left: 10px"
              disabled
              v-model="labInfoFormData.subjectId"
            ></el-input>
          </el-form-item>
          <el-form-item prop="categoryName">
            <span class="redStar">所属分类</span>
            <el-input
              disabled
              style="width: 400px; margin-left: 10px"
              v-model="labInfoFormData.categoryName"
            ></el-input>
          </el-form-item>
          <el-form-item prop="experimentName">
            <span class="redStar">实验名称</span>
            <el-input
              style="width: 400px; margin-left: 10px"
              v-model="labInfoFormData.experimentName"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="experimentCode">
            <span class="redStar">实验编号</span>
            <el-input
              style="width: 400px; margin-left: 10px"
              v-model="labInfoFormData.experimentCode"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="experimentPurpose">
            <span style="margin-left: 10px">实验目的</span>
            <el-input
              style="width: 400px; margin-left: 10px"
              v-model="labInfoFormData.experimentPurpose"
            ></el-input>
          </el-form-item>
          <el-form-item prop="experimentPrinciple">
            <span style="margin-left: 10px">实验原理</span>
            <el-input
              style="width: 400px; margin-left: 10px"
              v-model="labInfoFormData.experimentPrinciple"
              type="textarea"
              :rows="4"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item prop="experimentStep">
            <span style="margin-left: 10px">实验步骤</span>
            <el-input
              style="width: 400px; margin-left: 10px"
              v-model="labInfoFormData.experimentStep"
              type="textarea"
              :rows="4"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item prop="experimentDescription">
            <span style="margin-left: 38px">备注</span>
            <el-input
              style="width: 400px; margin-left: 10px"
              v-model="labInfoFormData.experimentDescription"
              type="textarea"
              :rows="4"
              resize="none"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right">
              <el-button
                v-throttle
                type="primary"
                @click="handleConfirmAddOrEditLabInfoBtnClick"
                >确认</el-button
              >
              <el-button
                v-throttle
                @click="
                  () => {
                    addOrEditLabInfoDialogVisible = false;
                  }
                "
                >取消</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
    <!-- 新增和编辑目录弹框 -->
    <el-dialog
      :title="addOrEditLabCategory === 1 ? '添加分类' : '编辑分类'"
      :visible.sync="addOrEditLabCategoryDialogVisible"
      width="550px"
      top="14%"
    >
      <div style="margin: 0 auto; width: 400px">
        <el-form
          :model="addOrEditLabCategoryFormData"
          ref="addOrEditLabCategoryFormData"
          :rules="rules"
          class="addOrEditLabCategoryFormData"
        >
          <el-form-item>
            <span class="redStar">所属科目</span>
            <el-input
              style="width: 300px; margin-left: 10px"
              disabled
              v-model="addOrEditLabCategoryFormData.subjectId"
            ></el-input>
          </el-form-item>
          <el-form-item prop="categoryName">
            <span class="redStar">分类名称</span>
            <el-input
              style="width: 300px; margin-left: 10px"
              placeholder="请输入"
              v-model="addOrEditLabCategoryFormData.categoryName"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item prop="categoryCode">
            <span class="redStar">分类编号</span>
            <el-input
              style="width: 300px; margin-left: 10px"
              placeholder="请输入"
              v-model="addOrEditLabCategoryFormData.categoryCode"
              onkeyup="this.value=this.value.replace(/\s/g,'')"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <div style="text-align: right">
              <el-button type="primary" @click="handleConfirmAddOrEditBtnClick"
                >确认</el-button
              >
              <el-button
                @click="
                  () => {
                    addOrEditLabCategoryDialogVisible = false;
                  }
                "
                >取消</el-button
              >
            </div>
          </el-form-item>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getMaterialMenuInfoApi,
  addMaterialMenuApi,
  deleteMaterialMenuApi,
  getMaterialMenuDetailApi,
  eidtMaterialMenuApi,
  getLabInfoFormMsgApi,
  addLabInfoFormMsgApi,
  deleteLabInfoFormMsgApi,
  getLabInfoDetailApi,
  eidtLabInfoFormMsgApi,
} from "@/api/standard/menuManagement.js";
export default {
  name: "menuManagement",
  data() {
    const checkCategoryCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z-_]/g;
      if (reg.test(this.addOrEditLabCategoryFormData.categoryCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    const checkExperimentCode = (rule, value, callback) => {
      let reg = /[^a-z0-9A-Z-_]/g;
      if (reg.test(this.labInfoFormData.experimentCode)) {
        callback(new Error("编号只能是数字或字母"));
      } else {
        callback();
      }
    };
    return {
      searchInputContent: "",
      addOrEditLabInfoDialogVisible: false,
      addOrEditLabInfo: 1, // 1表示添加实验，0表示编辑实验
      labFormCondition: {
        labCode: "",
        labName: "",
      },
      labInfoFormData: {
        subjectId: "",
        categoryName: "",
        experimentName: "",
        experimentCode: "",
        experimentPurpose: "",
        experimentPrinciple: "",
        experimentStep: "",
        experimentDescription: "",
      },
      menuTableDataList: [],
      labTableDataMsgPage: {
        pageIndex: 1,
        pageSize: 10,
        pageTotal: 0,
      },
      labTypeData: [],
      materialMenuParams: {
        categoryCode: "",
        categoryId: 0,
        categoryName: "",
        subjectId: 0,
        tenantId: 0,
      },
      addOrEditLabCategory: 1, // 1表示新增分类
      addOrEditLabCategoryDialogVisible: false,
      addOrEditLabCategoryFormData: {
        subjectId: "",
        categoryName: "",
        categoryCode: "",
      },
      rules: {
        categoryName: [
          { required: true, message: "请输入分类名称", trigger: "blur" },
        ],
        categoryCode: [
          { required: true, message: "请输入分类编号", trigger: "blur" },
          { validator: checkCategoryCode, trigger: "blur" },
        ],
        experimentName: [
          { required: true, message: "请输入实验名称", trigger: "blur" },
        ],
        experimentCode: [
          { required: true, message: "请输入实验编号", trigger: "blur" },
          { validator: checkExperimentCode, trigger: "blur" },
        ],
      },
      categoryId: "",
      categoryName: "",
      subjectName: "",
      subjectId: "",
      experimentId: "",

      subjectList: [],
    };
  },
  async created() {
    const { dataArr } = this.$store.state.public_data;
    await Promise.all(dataArr.map((e) => this.$store.dispatch("loadData", e)));

    // 科目
    this.subjectList = this.$store.state.public_data.sysSubjectDicList;

    this.fetchMaterialMenuData();
  },
  watch: {
    searchInputContent(val) {
      this.$refs.tree.filter(val);
    },
  },
  methods: {
    //   过滤
    filterNode(value, data) {
      // console.log(value,data)
      if (!value) return true;
      return data.label.indexOf(value) !== -1;
    },

    // 获取树形结构的数据
    fetchMaterialMenuData(data = this.materialMenuParams) {
      this.labTypeData = [];
      getMaterialMenuInfoApi(data).then((res) => {
        if (res.success) {
          const { data } = res;
          // console.log(data);
          let index = 1;
          // 先填充 一级标题
          data.forEach((item) => {
            this.labTypeData.push({
              id: index++,
              label: item.sysDicDTO.sysDicName,
              sysDicId: item.sysDicDTO.sysDicId,
            });
          });
          // 填充 二级标题
          data.forEach((item, i) => {
            if (item.experimentCategoryDTOList.length > 0) {
              if (!this.labTypeData[i].children) {
                this.labTypeData[i].children = [];
              }
              let subjectName = item.sysDicDTO.sysDicName;
              item.experimentCategoryDTOList.forEach((item2) => {
                this.labTypeData[i].children.push({
                  id: index++,
                  label: item2.categoryName,
                  categoryCode: item2.categoryCode,
                  categoryId: item2.categoryId,
                  categoryName: item2.categoryName,
                  subjectId: item2.subjectId,
                  subjectName: subjectName,
                  tenantId: item2.tenantId,
                });
              });
            }
          });
          // console.log(this.labTypeData);
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 获取实验信息表
    fetchLabInfoData(categoryId = this.categoryId, subjectId = this.subjectId) {
      let params = {
        categoryId,
        experimentCode: this.labFormCondition.labCode,
        experimentName: this.labFormCondition.labName,
        subjectId,
      };
      getLabInfoFormMsgApi(
        this.labTableDataMsgPage.pageIndex,
        this.labTableDataMsgPage.pageSize,
        params
      ).then((res) => {
        if (res.success) {
          this.menuTableDataList = res.data;
          this.labTableDataMsgPage.pageTotal = res.total;
        } else {
          this.$message.error(res.msg);
          return;
        }
      });
    },
    // 删除实验分类按钮
    handleDeleteLabCategoryBtnClick(data) {
      this.$confirm("确定要删除该实验分类吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteMaterialMenuApi(data.categoryId).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.fetchMaterialMenuData();
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 编辑实验分类按钮
    handleEditLabCategoryBtnClick(data) {
      this.addOrEditLabCategory = 0;
      console.log(data)
      this.categoryId = data.categoryId;
      this.addOrEditLabCategoryFormData = {
        subjectId: data.subjectName, // 显示名称
        categoryName: data.categoryName,
        categoryCode: data.categoryCode,
      };
      this.subjectId = data.subjectId
      this.addOrEditLabCategoryDialogVisible = true;
    },
    // 添加实验分类按钮
    handleAddLabCategoryBtnClick(data) {
      this.addOrEditLabCategory = 1;
      console.log(data)
      this.addOrEditLabCategoryFormData = {
        subjectId: data.label, // 显示名称
        categoryName: "",
        categoryCode: "",
      };
      this.addOrEditLabCategoryDialogVisible = true;
      this.$nextTick(() => {
        this.$refs.addOrEditLabCategoryFormData.clearValidate();
      });
    },
    // 确认添加或编辑实验分类按钮
    handleConfirmAddOrEditBtnClick() {
      this.$refs.addOrEditLabCategoryFormData.validate((valid) => {
        if (valid) {
          // 表单验证通过再进行操作
          if (this.addOrEditLabCategory === 1) {
            // 这里进行添加
            addMaterialMenuApi({
              categoryCode: this.addOrEditLabCategoryFormData.categoryCode,
              categoryId: 0,
              categoryName: this.addOrEditLabCategoryFormData.categoryName,
              subjectId: this.subjectId,
              tenantId: 0,
            }).then((res) => {
              if (res.success) {
                this.$message.success("添加实验分类成功!");
                this.fetchMaterialMenuData();
                this.addOrEditLabCategoryDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 这里进行编辑

            if (this.handleExpNameIsExist()) {
              return;
            }
            eidtMaterialMenuApi({
              categoryCode: this.addOrEditLabCategoryFormData.categoryCode,
              categoryId: this.categoryId,
              categoryName: this.addOrEditLabCategoryFormData.categoryName,
              subjectId: this.subjectId,
            }).then((res) => {
              if (res.success) {
                this.$message.success("编辑实验分类成功!");
                this.fetchMaterialMenuData();
                this.addOrEditLabCategoryDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 树形结构点击事件
    handleTreeNodeClick(data) {
      this.subjectId = data.subjectId ? data.subjectId : data.sysDicId;
      this.subjectName = data.subjectName ? data.subjectName : data.label;
      this.categoryId = data.categoryId ? data.categoryId : "";
      this.categoryName = data.categoryName ? data.categoryName : "";
      this.fetchLabInfoData();
    },
    // 重置按钮
    handleResetLabTableBtnClick() {
      this.labFormCondition = {
        labCode: "",
        labName: "",
      };
      this.fetchLabInfoData();
    },
    // 查询按钮
    handleQueryLabTableBtnClick() {
      this.labTableDataMsgPage.pageIndex = 1;
      this.labTableDataMsgPage.pageSize = 10;
      this.fetchLabInfoData();
    },
    // 添加实验按钮
    handleAddLabBtnClick() {
      if (this.categoryName === "") {
        this.$message.warning("请选择一个实验分类!");
        return;
      }
      this.addOrEditLabInfo = 1;
      this.labInfoFormData = {
        subjectId: this.subjectName,
        categoryName: this.categoryName,
        experimentName: "",
        experimentCode: "",
        experimentPurpose: "",
      };
      this.addOrEditLabInfoDialogVisible = true;
    },
    // 编辑实验
    handleEditLabBtnClick(row) {
      this.addOrEditLabInfo = 0;
      this.labInfoFormData = {
        subjectId: this.subjectName,
        categoryName: this.categoryName,
        experimentName: row.experimentName,
        experimentCode: row.experimentCode,
        experimentPurpose: row.experimentPurpose,
        experimentPrinciple: row.experimentPrinciple,
        experimentStep: row.experimentStep,
        experimentDescription: row.experimentDescription,
      };
      this.experimentId = row.experimentId;
      this.addOrEditLabInfoDialogVisible = true;
    },
    // 删除实验
    handleDeleteLabBtnClick(id) {
      // console.log(id);
      this.$confirm("确定要删除该实验吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          deleteLabInfoFormMsgApi(id).then((res) => {
            if (res.success) {
              this.$message.success("删除成功!");
              this.fetchLabInfoData();
            } else {
              this.$message.error(res.msg);
              return;
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    // 确认新增或编辑实验按钮
    handleConfirmAddOrEditLabInfoBtnClick() {
      this.$refs.labInfoFormData.validate((valid) => {
        if (valid) {
          if (this.addOrEditLabInfo === 1) {
            // 添加实验
            addLabInfoFormMsgApi({
              ...this.labInfoFormData,
              categoryId: this.categoryId,
            }).then((res) => {
              if (res.success) {
                this.$message.success("添加成功!");
                this.fetchLabInfoData();
                this.addOrEditLabInfoDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          } else {
            // 编辑实验
            eidtLabInfoFormMsgApi({
              ...this.labInfoFormData,
              experimentId: this.experimentId,
              categoryId: this.categoryId,
            }).then((res) => {
              if (res.success) {
                this.$message.success("修改成功!");
                this.fetchLabInfoData();
                this.addOrEditLabInfoDialogVisible = false;
              } else {
                this.$message.error(res.msg);
                return;
              }
            });
          }
        }
      });
    },
    // 分页切换当前页
    handleCurrentChangeLabTableDataMsgPage(val) {
      this.labTableDataMsgPage.pageIndex = val;
      this.fetchLabInfoData();
    },
    // 分页改变页容量
    handleSizeChangeLabTableDataMsgPage(val) {
      this.labTableDataMsgPage.pageIndex = 1;
      this.labTableDataMsgPage.pageSize = val;
      this.fetchLabInfoData();
    },
    // 判断实验分类名称是否已存在
    handleExpNameIsExist() {
      let result = false;
      console.log(this.labTypeData);
      this.labTypeData.forEach((item) => {
        if (item.sysDicId == this.subjectId) {
          item.children.forEach((it) => {
            if (
              it.categoryName ===
                this.addOrEditLabCategoryFormData.categoryName &&
              it.categoryId !== this.categoryId
            ) {
              this.$message.error("实验分类名已存在");
              result = true;
            }
          });
        }
      });
      console.log(result);
      return result;
    },
    // 按科目筛选一级标题
    assistFunc(label) {
      let result = false;
      this.subjectList.forEach((item) => {
        if (item.label === label) {
          result = true;
        }
      });
      return result;
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-container {
  width: 100%;
  height: 100%;
  display: flex;
  .lab-type-container {
    width: 25%;
    height: 95%;
    border-right: 1px solid #ccc;
    margin-right: 20px;
    border-radius: 5px;
    padding: 10px;
  }
  .lab-list-container {
    width: 75%;
    height: 95%;
    border-radius: 5px;
    padding: 10px;
  }
}
.title {
  padding-left: 5px;
  font-size: 18px;
  height: 18px;
  line-height: 18px;
  border-left: 3px solid #ccc;
  margin-bottom: 15px;
}
::v-deep .tree-container {
  .el-tree-node {
    margin: 10px 0;
  }
  .is-current {
    background-color: rgb(245, 247, 250);
  }
}
.redStar::before {
  content: "*";
  color: #f56c6c;
  margin-right: 4px;
}
::v-deep .addOrEditLabCategoryFormData {
  .el-form-item__error {
    margin-left: 75px;
  }
}
::v-deep .labInfoFormData {
  .el-form-item__error {
    margin-left: 75px;
  }
}
</style>
